<template>
  <div>
    <div :style="{ visibility: isLoading ? 'hidden' : 'visible' }">
      <slot />
    </div>
    <div v-if="isLoading" class=" p-3 d-flex justify-content-center align-items-center gap-2">
      <Icon icon="svg-spinners:eclipse-half" class="text-2xl" /> {{ info }}
    </div>
  </div>
</template>

<script setup>
const props = defineProps([ "isLoading", "info" ]);
const isLoading = ref(props.isLoading);

watch(() => props.isLoading, (newValue) => {
  isLoading.value = newValue;
});
</script>
